.wrapper {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  min-height: 100vh; /* Min height instead of fixed height */
  padding: 20px 0;
  box-sizing: border-box;
  overflow-y: auto;
}

.container {
  display: flex;
  flex-direction: row;
  width: 90%;
  max-width: 1200px;
  min-width: 320px;
  padding: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: white;
  margin: 0 auto;
  height: auto;
  max-height: 85vh;
}

.form-title {
  font-weight: bold;
  color: #4f5357;
}

.skipButtonContainer {
  position: absolute;
  top: 20px;
  right: 20px;
}

.skip-button {
  background-color: #f3f3f3;
  color: #5c5c5c;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
}

.skip-button:hover {
  background-color: #e9e9e9;
  color: #161616;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.left-section {
  width: 45%;
  padding: 15px 25px;
  box-sizing: border-box;
  min-width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.right-section {
  width: 55%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  padding: 15px;
  overflow: hidden;
}

.logo {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.steps {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  position: relative;
}

.steps::after {
  content: "";
  position: absolute;
  top: 15px;
  left: 30px;
  width: 30px;
  height: 2px;
  background-color: #d3d3d3;
  z-index: 0;
}

.steps div {
  width: 30px;
  height: 30px;
  background-color: var(--lw-blue-color);
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  position: relative;
  z-index: 1;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.steps div:not(.active) {
  background-color: #d3d3d3;
}

.welcome {
  font-size: 26px;
  font-weight: bold;
  margin-bottom: 12px;
  letter-spacing: -0.5px;
}

.welcome-message {
  font-size: 16px;
  color: #6c757d;
  margin-bottom: 24px;
  line-height: 1.5;
  max-width: 90%;
}

.form-group {
  background-color: #e9ecef;
  padding: 22px;
  border-radius: 8px;
  margin-bottom: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.form-title {
  margin-bottom: 12px;
  font-size: 15px;
}

.form-group select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ced4da;
  border-radius: 6px;
  margin-bottom: 10px;
  font-size: 14px;
}

.form-group p {
  font-size: 14px;
  color: #6c757d;
}

.buttons {
  margin-top: 16px;
  display: flex;
  justify-content: space-between; /* Align buttons to opposite ends */
  margin-bottom: 10px;
}

.prev-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
  border: 2px solid var(--lw-blue-color);
  color: var(--lw-blue-color);
  padding: 10px 22px;
  border-radius: 6px;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
  min-width: 110px;
}

.prev-button:hover {
  background-color: var(--lw-blue-color);
  color: white;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.next-button {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
  background-color: var(--lw-blue-color);
  color: white;
  padding: 10px 22px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-width: 110px;
}

.next-button:hover:not(:disabled) {
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.next-button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.overview-text {
  margin-top: 10px;
  color: #6c757d;
  font-size: 14px;
}

.inputContainer {
  margin-top: 10px;
}

.inputLabel {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #4f5357;
}

.input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 6px;
  transition: all 0.2s ease;
  color: #23282c;
  font-size: 14px;
  background-color: #fff;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05);

  &:focus {
    border-color: var(--lw-blue-color);
    outline: none;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.05),
      0 0 0 3px rgba(var(--lw-blue-color-rgb), 0.1);
  }
}

.invalidInput {
  border-color: #e74c3c;
  background-color: rgba(231, 76, 60, 0.03);
}

.inputHelp {
  display: block;
  margin-top: 6px;
  font-size: 12px;
  color: #777;
  line-height: 1.4;
}

.requiredAsterisk {
  color: #e74c3c;
  margin-left: 0.25em;
  font-weight: bold;
}

.video {
  border-radius: 6px;
  max-width: 100%;
  height: auto;
  object-fit: contain;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 12px;
}

@media (max-width: 992px) {
  .container {
    flex-direction: column;
    padding: 20px;
    max-height: none;
    width: 85%;
  }

  .left-section,
  .right-section {
    width: 100%;
  }

  .right-section {
    margin-top: 20px;
    max-width: 650px;
    align-self: center;
  }

  .welcome-message {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .container {
    width: 90%;
    padding: 20px 15px;
  }
}

@media (max-width: 576px) {
  .wrapper {
    min-height: auto;
    padding: 15px 0;
  }

  .container {
    width: 95%;
    padding: 15px 12px;
    border-radius: 8px;
  }

  .left-section,
  .right-section {
    padding: 8px;
  }

  .form-group {
    padding: 16px;
    margin-bottom: 16px;
  }

  .welcome {
    font-size: 20px;
  }

  .skipButtonContainer {
    top: 10px;
    right: 10px;
  }

  .steps {
    margin-bottom: 16px;
  }

  .steps div {
    width: 26px;
    height: 26px;
    font-size: 14px;
  }

  .steps::after {
    top: 13px;
    width: 26px;
  }

  .input,
  .form-group select {
    padding: 10px;
  }
}
